import React, { Component } from "react";
import { withRouter } from "react-router"; // Importar withRouter
import GoogleAnalytics from "react-ga";
import { addScriptDefault } from 'meta-pixel';
import Axios from "axios";
import { GET_MERCHANT_INFO_BY_ID_URL } from "./configs";

// Variável para armazenar a função reloadTracking para exportar
let reloadTrackingExternally;

const withTracker = (WrappedComponent, options = {}) => {

  let merchantData = null;

  const fetchMerchantInfo = async () => {
    // Tentar obter o merchant ID do localStorage
    const merchantId = localStorage.getItem("lastMerchantOpen");

    if (!merchantId) {
      console.error("Nenhum merchant ID encontrado no localStorage.");
      return null; // Se não houver, retorne null para evitar erros
    }

    try {
      const response = await Axios.post(`${GET_MERCHANT_INFO_BY_ID_URL}/${merchantId}`);
      merchantData = response.data;
      console.log("Dados do merchant carregados:", merchantData);
      return merchantData;
    } catch (error) {
      console.error("Erro ao buscar informações do merchant:", error);
      return null;
    }
  };

  const initializeTracking = (merchantData, page) => {
    if (!merchantData) {
      console.error("Nenhum merchant ativo encontrado.");
      return;
    }

    const { 
      meta_pixel_id, 
      meta_pixel_active, 
      google_analytics_id, 
      google_analytics_active, 
      google_tag_manager_id, 
      google_tag_manager_active 
    } = merchantData;

    // Configurar Google Analytics
    if (google_analytics_active && google_analytics_id) {
      GoogleAnalytics.initialize(google_analytics_id);
      GoogleAnalytics.set({ page, ...options });
      GoogleAnalytics.pageview(page);
    }

    // Configurar Meta Pixel
    if (meta_pixel_active && meta_pixel_id) {
      if (!window.fbqInitialized) { // Evita inicialização duplicada
        const fbq = addScriptDefault(); // Adiciona o script do Meta Pixel
        fbq('set', 'autoConfig', meta_pixel_id);
        fbq('init', meta_pixel_id);
        fbq('track', 'PageView'); // Rastreia a visualização da página
        window.fbqInitialized = true; // Marca que o Pixel foi inicializado
      }
    }

    // Configurar Google Tag Manager, se necessário
    if (google_tag_manager_active && google_tag_manager_id) {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        // eslint-disable-next-line eqeqeq
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', google_tag_manager_id);
    }
  };

  const triggerCustomPixelEvent = (eventName, eventParams = {}) => {
    // Disparar o evento personalizado do Pixel
    if (window.fbqInitialized) {
      window.fbq('track', eventName, eventParams);
    } else {
      console.error("Meta Pixel não está inicializado, o evento não pode ser disparado.");
    }
  };

  const reloadTracking = async () => {
    // Função para recarregar o tracking
    window.fbqInitialized = false; // Limpar variáveis globais para garantir reinicialização
    const merchantData = await fetchMerchantInfo();
    const page = window.location.pathname;
    initializeTracking(merchantData, page);
  };

  // Armazenar a função reloadTracking para uso externo
  reloadTrackingExternally = reloadTracking;

  const HOC = class extends Component {
    async componentDidMount() {
      const page = this.props.location.pathname; // Agora terá acesso a 'location'
      const merchantData = await fetchMerchantInfo();
      initializeTracking(merchantData, page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = this.props.location.pathname;

      if (currentPage !== nextPage) {
        initializeTracking(merchantData, nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} triggerPixelEvent={triggerCustomPixelEvent} reloadTracking={reloadTracking} />;
    }
  };

  return withRouter(HOC); // Use withRouter para passar as props de rota
};

// Expor a função `reloadTracking` para uso externo
export const externalReloadTracking = () => reloadTrackingExternally();

export default withTracker;
