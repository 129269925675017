import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

/**
 * Wraps the React Router Link component and creates a delay after the link is clicked.
 */
class DelayLink extends Component {
    static defaultProps = {
        delay: 0,
        onDelayStart: () => { },
        onDelayEnd: () => { }
    };

    handleClick = e => {
        const { replace, to, delay, onDelayStart, onDelayEnd, history } = this.props;

        if (this.props.clickAction) {
            this.props.clickAction();
        }
        onDelayStart(e, to);
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        this.timeout = setTimeout(() => {
            if (replace) {
                history.replace(to);
            } else {
                history.push(to);
            }
            onDelayEnd(e, to);
        }, delay);
    };

    render() {
        const props = Object.assign({}, this.props);
        delete props.delay;
        delete props.onDelayStart;
        delete props.onDelayEnd;
        delete props.clickAction;

        return <Link {...props} onClick={this.handleClick} />;
    }
}

export default withRouter(DelayLink);
