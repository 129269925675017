import React, { Component } from "react";

class DeliveryTime extends Component {
  render() {
    const { rate_and_time } = this.props;

    if (!rate_and_time || rate_and_time.length === 0) {
      return <span className="delivery-time">Indisponível</span>;
    }

    const times = rate_and_time.map((rate) => parseInt(rate.time, 10));
    const minTime = Math.min(...times);
    const maxTime = Math.max(...times);

    return (
      <span className="delivery-time">
        {minTime} - {maxTime} Min
      </span>
    );
  }
}

export default DeliveryTime;
