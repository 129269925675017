import { CLEAR_SEARCH, SEARCH_MERCHANTS } from "./actionTypes";

const initialState = {
	merchants: [],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SEARCH_MERCHANTS:
			return { ...state, merchants: action.payload };

		case CLEAR_SEARCH:
			return { ...state, merchants: action.payload };
		default:
			return state;
	}
}
