import {
	CHECK_MERCHANT_OPERATION_SERVICE,
	GET_MERCHANTS_BASED_ON_CATEGORY,
	GET_MERCHANTS_CATEGORIES,
	GET_DELIVERY_MERCHANTS,
	GET_SELFPICKUP_MERCHANTS,
	GET_FAVORITE_MERCHANTS,
} from "./actionTypes";
import {
	CHECK_MERCHANT_OPERATION_SERVICE_URL,
	GET_MERCHANTS_CATEGORIES_URL,
	GET_FILTERED_MERCHANTS_URL,
	GET_DELIVERY_MERCHANTS_URL,
	GET_SELFPICKUP_MERCHANTS_URL,
	GET_FAVORITE_MERCHANTS_URL,
} from "../../configs";
import Axios from "axios";

export const checkMerchantOperationService = (merchant_id, latitude, longitude) => (dispatch) => {
	Axios.post(CHECK_MERCHANT_OPERATION_SERVICE_URL, {
		merchant_id: merchant_id,
		latitude: latitude,
		longitude: longitude,
	})
		.then((response) => {
			const coupon = response.data;
			return dispatch({ type: CHECK_MERCHANT_OPERATION_SERVICE, payload: coupon });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getMerchantsBasedOnCategory = (lat, lng, category_ids) => (dispatch) => {
	Axios.post(GET_FILTERED_MERCHANTS_URL, {
		latitude: lat,
		longitude: lng,
		category_ids: category_ids,
	})
		.then((response) => {
			const filtered_merchants = response.data;
			return dispatch({
				type: GET_MERCHANTS_BASED_ON_CATEGORY,
				payload: filtered_merchants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getMerchantsCategories = (slug) => (dispatch) => {
	Axios.post(GET_MERCHANTS_CATEGORIES_URL)
		.then((response) => {
			const merchants_categories = response.data;
			return dispatch({
				type: GET_MERCHANTS_CATEGORIES,
				payload: merchants_categories,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getDeliveryMerchants = (lat, lng) => (dispatch) => {
	return Axios.post(GET_DELIVERY_MERCHANTS_URL, {
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const merchants = response.data;
			return dispatch({
				type: GET_DELIVERY_MERCHANTS,
				payload: merchants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getSelfpickupMerchants = (lat, lng) => (dispatch) => {
	return Axios.post(GET_SELFPICKUP_MERCHANTS_URL, {
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const merchants = response.data;
			return dispatch({
				type: GET_SELFPICKUP_MERCHANTS,
				payload: merchants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getFavoriteMerchants = (lat, lng) => (dispatch, getState) => {
	return Axios.post(GET_FAVORITE_MERCHANTS_URL, {
		token: getState().user.user.data.auth_token,
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const merchants = response.data;
			return dispatch({
				type: GET_FAVORITE_MERCHANTS,
				payload: merchants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const clearMerchantList = () => (dispatch) => {
	const merchants = [];
	return dispatch({
		type: GET_DELIVERY_MERCHANTS,
		payload: merchants,
	});
};
