import {
	CHECK_MERCHANT_OPERATION_SERVICE,
	GET_MERCHANTS_BASED_ON_CATEGORY,
	GET_MERCHANTS_CATEGORIES,
	GET_DELIVERY_MERCHANTS,
	GET_SELFPICKUP_MERCHANTS,
	GET_FAVORITE_MERCHANTS
} from "./actionTypes";

const initialState = {
	merchants: [],
	is_operational: "",
	filtered_merchants: [],
	merchants_categories: [],
	favoriteMerchants: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case CHECK_MERCHANT_OPERATION_SERVICE:
			return { ...state, is_operational: action.payload };
		case GET_MERCHANTS_BASED_ON_CATEGORY:
			return { ...state, filtered_merchants: action.payload };
		case GET_MERCHANTS_CATEGORIES:
			return { ...state, merchants_categories: action.payload };

		case GET_DELIVERY_MERCHANTS:
			return { ...state, merchants: action.payload };

		case GET_SELFPICKUP_MERCHANTS:
			return { ...state, merchants: action.payload };
			
		case GET_FAVORITE_MERCHANTS:
			return { ...state, favoriteMerchants: action.payload };

		default:
			return state;
	}
}
